<template>
	<!-- 返现活动资金日志 -->
	<div class="fundlog el-content">
		<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'用户UID',dataIndex:'user_id'},
			{title:'头像',dataIndex:'avatar',slots:{customRender:'avatar'}},
			{title:'用户昵称',dataIndex:'user.nickname'},
			{title:'订单信息',dataIndex:'order',slots:{customRender:'order'}},
			{title:'已执行次数',dataIndex:'now_count'},
			{title:'总执行次数',dataIndex:'exec_count'},
			{title:'每次执行返现金额(元)',dataIndex:'price'},
			{title:'创建时间',dataIndex:'create_time'},
		]" >
		<template #avatar="{record}">
			<a-avatar :src="record.user?record.user.avatar_url :''"></a-avatar>
		</template>
		<template #order="{record}">
			<div><span class="f12">订单ID：</span>{{record.order.id}}</div>
			<div><span class="f12">说明：</span>{{record.order.msg}}</div>
		</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getLogList(info.page,e)}"
				@change="(e)=>{getLogList(e,info.limit)}"
			/>
		</div>
	</div>
	
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import cashbackModel from '@/api/addons/cashback'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
		})
		const opt = useRoute().query
		getLogList(1,state.info.limit)
		function getLogList(page,limit){
			cashbackModel.getCashBackLog(page,limit,{id:opt.id},res=>state.info = {limit,...res})
		}
		return{
			...toRefs(state)
		}
	},
}
</script>

<style>
	
</style>
